import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import './theme/element/index.css'
import axios from 'axios'
import * as echarts from 'echarts';
import * as socketApi from './api/websocket/websocket'
// Vue.prototype.socketApi = socketApi

Vue.use(Element)

Vue.config.productionTip = false
Vue.use(Element)
Vue.prototype.$echarts = echarts;

new Vue({
  // 这里为全局监听socket事件消息，不需要全局可以放到组件里面去。
  router,
  store,
  render: h => h(App)
}).$mount('#app')
